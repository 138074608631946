:root {
  --evogunmetal: #203544;
  --brightblue: #00b3f0;
  --evoblue: #0cb3d7;
  --evoBlueTenPercent: rgba(12, 179, 215, 0.2);
  --evogreen: #00bf00;
  --mutedgreen: #6ba368;
  --darkspringgreen: #04724d;
  --burntorange: #ba5624;
  --claret: #8b2635;
  --cordovan: #764248;
  --evoburgundy: #bf0000;
}

@font-face {
  font-family: "ProximaNova-Regular";
  font-display: swap;
  font-weight: normal;
  src: url("./assets/fonts/proxima/392B84_E_0.eot");
  src: url("./assets/fonts/proxima/392B84_E_0.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/proxima/392B84_E_0.woff2") format("woff2"),
    url("./assets/fonts/proxima/392B84_E_0.woff") format("woff"),
    url("./assets/fonts/proxima/392B84_E_0.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  font-display: swap;
  src: url("./assets/fonts/proxima/393142_5_0.eot");
  src: url("./assets/fonts/proxima/393142_5_0.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/proxima/393142_5_0.woff2") format("woff2"),
    url("./assets/fonts/proxima/393142_5_0.woff") format("woff"),
    url("./assets/fonts/proxima/393142_5_0.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Bold";
  font-display: swap;
  src: url("./assets/fonts/proxima/393142_2_0.eot");
  src: url("./assets/fonts/proxima/393142_2_0.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/proxima/393142_2_0.woff2") format("woff2"),
    url("./assets/fonts/proxima/393142_2_0.woff") format("woff"),
    url("./assets/fonts/proxima/393142_2_0.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "ProximaNova-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

.logo-container {
  width: 240px;
  margin-left: 50px;
  margin-top: 4px;
}

.logo-container > a > img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

.userImg {
  border-radius: 100px;
  display: block;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}

.hero .app-logo {
  max-width: 10.5rem;
}

.hero {
  margin-top: 30px;
}

.logo {
  background: url("https://thinkcodenyc.com/wp-content/uploads/2014/05/logo-gray-retina.png");
  width: 180px;
  height: 71px;
  background-size: cover;
}

.rbt {
  /* margin: 20px 0; */
}
#addFlightHeading,
#addAirportHeading {
  /* margin-top: 60px; */
}

#addAirportHeading {
  text-align: center;
}

form#addFlight,
form#addAirport,
form.updateDocumentForm,
form.calculatorForm,
form#editFlight,
form.base-form.two-column {
  width: 60%;
  margin: 20px auto 80px;
}
input[type="submit"] {
  background: var(--evoblue);
  color: #fff;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  /* margin-bottom: 100px; */
  outline: none;
  transition: 0.35s all ease;
  font-weight: normal !important;
}

.centered {
  text-align: center;
}

textarea,
.react-date-picker__wrapper,
#searchFlights {
  width: 100%;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* margin: 0 0 20px; */
}
.react-date-picker {
  width: 100%;
}

.ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto 30px;
}
.ripple div {
  position: absolute;
  border: 4px solid #3e9dd7;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.navbar .router-link-exact-active {
  border-bottom-color: #3e9dd7;
}

.hero p.lead {
  margin-bottom: 50px;
}

#searchFlights {
  width: 40%;
  margin: 0 auto 50px;
  display: block;
}

.data-table-extensions > .data-table-extensions-filter > .icon,
.data-table-extensions > .data-table-extensions-action > button.print {
  display: none !important;
}

form#addFlight select,
form#editFlight select,
.containsNumberInput input {
  /* margin-bottom: 20px; */
  width: 100%;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#table-bulk-actions {
  display: flex;
  margin-bottom: 25px;
}

#table-bulk-actions #deleteButton {
  /* margin-left: auto; */
  margin-left: 30px;
  margin-right: 10px;
  padding: 8px 15px;
}

#deleteButton {
  background: var(--evogunmetal);
  color: #fff;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-family: "ProximaNova-Semibold" !important;
}

.base-button {
  color: #fff;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  outline: none;
  font-family: "ProximaNova-Semibold";
  transition: all 0.35s ease;
}

.base-button.evo-blue,
.evo-blue {
  background-color: var(--evoblue);
}
.evo-gunmetal {
  background-color: var(--evogunmetal);
}
.base-button.evo-gunmetal:hover {
  background-color: var(--evoblue);
}

.evo-burgundy {
  background-color: var(--evoburgundy);
}

.base-button:focus {
  outline: none;
}

.base-button:hover {
  background-color: var(--evogunmetal);
  color: #fff;
}

#deleteButton[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.basic-multi-select {
  margin: 0 0 20px 0;
}

#table-bulk-actions .basic-multi-select {
  flex-basis: 25%;
  margin-left: auto;
  margin-bottom: 0;
}

/* #departure-airport {
  display: none !important;
} */

.rbt-loader {
  display: none;
}

.rdt_TableCol,
.rdt_TableCol div {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  text-transform: uppercase;
}

.Toastify__toast--success {
  background: var(--evoblue);
  font-family: "ProximaNova-Semibold";
}

.Toastify__toast {
  font-family: "ProximaNova-Semibold";
}

#addFlight input[type="submit"],
#editFlight input[type="submit"],
#addAirport input[type="submit"],
form.updateDocumentForm input[type="submit"],
form.calculatorForm input[type="submit"],
form.base-form input[type="submit"] {
  background: var(--evoblue);
  outline: none !important;
  transition: 0.35s all ease-in-out;
}

#addFlight input[type="submit"]:hover,
#editFlight input[type="submit"]:hover,
#addAirport input[type="submit"]:hover,
form.updateDocumentForm input[type="submit"]:hover,
form.calculatorForm input[type="submit"]:hover,
form.base-form input[type="submit"]:hover {
  background: var(--evogunmetal);
}

#addAirport input[type="text"],
#addAirport input[type="number"],
form.updateDocumentForm input[type="text"],
form.updateDocumentForm input[type="number"],
form.base-form input[type="text"],
form.base-form input[type="email"],
form.base-form input[type="tel"],
form.base-form input[type="password"],
.PhoneInput input,
.base-input {
  width: 100%;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0 0 20px;
  display: block;
}

.semibold-text {
  font-family: "ProximaNova-Semibold" !important;
}

.PhoneInput input {
  margin-bottom: 0;
}

.PhoneInput {
  margin-bottom: 20px;
  width: 100%;
}

label.phoneInputLabel {
  display: block;
}

a {
  color: var(--evoblue);
  font-family: "ProximaNova-Semibold" !important;
  font-weight: normal !important;
  transition: all 0.5s ease;
}

a:hover {
  color: var(--evogunmetal);
  text-decoration: none;
}

#addAirport,
form.updateDocumentForm,
form.calculatorForm,
form.base-form.two-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-top: 40px !important;
}

#addAirport .form-group,
g form.updateDocumentForm .form-group,
form.base-form.two-column .form-group {
  flex-basis: 48%;
  margin-right: 2%;
}

#addAirport .form-group:nth-of-type(even),
form.updateDocumentForm .form-group:nth-of-type(even),
form.base-form.two-column .form-group:nth-of-type(even) {
  margin-left: 2%;
  margin-right: 0;
}

#addAirport .form-group label,
form.updateDocumentForm .form-group label,
form.calculatorForm .form-group label,
#addFlight .form-group label,
#editFlight .form-group label,
form.base-form .form-group label,
label.phoneInputLabel,
.form-group.no-form label,
.base-label {
  font-family: "ProximaNova-Semibold";
  font-weight: normal;
  text-transform: uppercase;
}

#table-bulk-actions #deleteButton.align-right {
  margin-left: auto;
  display: flex;
}

footer p {
  margin: 0.5rem 0;
}

footer {
  min-height: unset;
  margin-top: 30px;
}

.checkboxContainer {
  flex-basis: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  width: 60%;
  margin: 20px auto 0;
}

.checkboxContainer label {
  margin-bottom: 0;
  font-family: "ProximaNova-Semibold";
  margin-right: 10px;
  text-transform: uppercase;
}

.checkboxContainer input[type="checkbox"] {
  top: -2px;
  position: relative;
}

.airportAutocompleteContainer {
  width: 100%;
  margin-top: 2px;
}

.calculatorForm .form-group {
  width: 100%;
}
.rbt .form-control,
.rbt.airportAutocompleteContainer {
  margin-bottom: 0 !important;
}

form.calculatorForm input[type="text"],
form.calculatorForm
  input[type="number"]:not(.react-date-picker__inputGroup__input),
form input[type="phone"],
form input[type="email"],
form#editSpread input[type="number"] {
  width: 100%;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* margin: 0 0 20px; */
  display: block;
}

form.calculatorForm select {
  /* margin-bottom: 20px; */
  width: 100%;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

form.calculatorForm {
  margin-bottom: 0;
}

form.calculatorForm input[type="submit"] {
  margin-bottom: 40px;
}
#results .rdt_Table,
#results .rdt_TableHeadRow,
#results .rdt_TableRow,
#results .rdt_TableBody,
#results > div > div {
  width: auto !important;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 95%;
  }
}

#results {
  margin: 0 auto;
  display: block;
  width: 800px;
}

.aircraft-select,
.owner-select {
  /* margin-bottom: 20px; */
}
#addFlight .airportAutoCompleteLabel,
#editFlight .airportAutoCompleteLabel {
  display: none;
  margin-bottom: 0;
}

#json-pretty {
  margin-top: 30px;
}
#search-bulk-actions-container {
  display: flex;
  align-items: flex-start;
}

#searchFlights {
  display: flex;
  margin: unset;
  width: unset;
  flex-basis: 25%;
}

#table-bulk-actions {
  display: flex;
  margin-bottom: 25px;
  flex-basis: 75%;
}

#results {
  width: auto;
}

#results > div {
  width: auto !important;
  display: block !important;
}

#results > div > div {
  margin: auto;
}
h2.form-heading {
  text-align: center;
}

button.lead-outreach-assignment {
  background: var(--evoblue);
  border: none;
  color: #fff;
  padding: 10px;
  margin: 4px;
}

#lead-bulk-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
}

.lead-search {
  margin-left: auto !important;
  display: flex;
  margin-right: 0 !important;
  flex-basis: unset !important;
}

.lead-form-heading {
  margin-top: 30px;
}

#lead-bulk-actions button[disabled] {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.25);
}

#filterByStage {
  margin-left: auto;
  width: auto;
  display: inline-block;
  max-width: 300px;
}

div#lead-bulk-actions > div {
  flex-grow: 0.5;
  column-gap: 20px;
  row-gap: 20px;
  gap: 20px;
  /* margin: 0 10px; */
}

.two-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.two-fields .form-group {
  margin-right: 20px;
}

#newQuote label {
  font-family: "ProximaNova-Semibold";
  font-weight: normal;
  text-transform: uppercase;
}
#newQuote .form-group label {
  display: block;
}

#newQuote input[type="text"],
#newQuote input[type="number"]:not(.react-date-picker__inputGroup__input) {
  /* width: 100%; */
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.flight-details-container {
  background: #fff;
}
#addLeg {
  /* margin-left: 30px; */
  /* margin-bottom: 30px; */
}

.three-fields,
.two-fields,
.flex-row {
  display: flex;
  /*align-items: center;*/
  /* justify-content: center; */
}

.invoice-totals {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.invoice-totals-parent {
  display: flex;
  flex-direction: column;
}

.h3-total {
  width: 15%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-half {
  flex: 0.5;
}

.column-gap-2pct {
  column-gap: 2%;
}

.column-gap-1pct {
  column-gap: 1%;
}

.column-gap-20 {
  column-gap: 20px;
}

.gap-20 {
  gap: 20px;
}

.three-fields > div {
  flex-basis: 33%;
  margin: 0 20px 0 0;
}

.two-fields > div {
  flex-basis: 48%;
  margin: 0 20px 0 0;
}

.flight-details-block .three-fields:nth-child(2) {
  margin-top: 20px;
  margin-bottom: 20px;
}

.three-fields > div input {
  width: 100%;
}

.two-fields > div input {
  width: 100%;
}

.aircraft-options-block .two-fields {
  margin-top: 20px;
  margin-bottom: 20px;
}

#newQuote button.action-button,
.base-blue-button {
  background: var(--evoblue);
  border: none;
  padding: 10px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
}

.proeminent-button {
  background: var(--evoblue);
  border: none;
  padding: 20px;
  color: #fff;
  background-color: var(--evogunmetal);
  font-family: "ProximaNova-Semibold";
  font-size: large;
}

.base-gunmetal-button {
  background: var(--evogunmetal);
  border: none;
  padding: 10px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
}

#addLeg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.leg-duration-block {
  display: flex;
}

.leg-duration-block .form-group {
  margin: 0 0 20px !important;
}

.subject-field {
  width: 100%;
}

#addOption {
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
}
.checkbox-list div {
  flex-basis: 100%;
}
.checkbox-list label {
  margin-left: 5px;
}

.checkbox-list {
  margin-top: 10px;
  margin-bottom: 20px;
}

.aircraft-checkboxes {
  display: flex;
  align-items: flex-start;
}

.aircraft-checkboxes .checkbox-list {
  /* flex-basis: 48%; */
}

.rdw-editor-wrapper {
  border: 1px solid #ced4da;
  margin: 20px 0 30px;
  border-radius: 0.25rem;
}

.rdw-editor-main {
  padding: 0 15px;
}

.rdw-editor-toolbar {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

#newQuote input[type="submit"] {
  background-color: var(--evoblue);
}

#quoteEmailPreview {
  height: 800px;
  overflow: scroll;
}

.file-dropzone {
  margin-top: 30px;
}

.file-dropzone p {
  margin-bottom: 0;
  padding: 20px;
}

button.closeLead {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--evoblue);
  font-family: "ProximaNova-Semibold" !important;
  font-weight: normal !important;
  transition: all 0.5s ease;
}

button.closeLead:hover {
  color: var(--evogunmetal);
}

.ReactModal__Content--after-open {
  display: flex;
  /* width: 50%; */
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative !important;
  margin: auto !important;
  inset: unset !important;
}

.ReactModal__Content--after-open input[type="submit"] {
  margin-bottom: 20px;
}

.ReactModal__Overlay--after-open {
  display: flex;
}

#warmProspectForm h3 {
  margin-bottom: 25px;
  margin-top: 25px;
}

#page-header .page-pretitle {
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #6c757d;
  font-family: "ProximaNova-Semibold";
  margin-top: 30px;
  font-size: 12px;
}

#page-header h1.page-title {
  font-family: "ProximaNova-Bold", sans-serif !important;
  font-weight: normal !important;
  font-size: 25px;
  padding-bottom: 20px;
  color: rgba(51, 51, 51, 0.81);
  text-transform: capitalize !important;
}

#page-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-bottom: 1px solid #e3ebf6;
}

#page-breadcrumb {
  align-self: center;
  margin-left: auto;
}
#page-title-container {
  align-self: center;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
  padding-right: 0.5rem;
  color: #ced4da;
  content: "\e5cc";
  font-family: "Material Design Icons";
}

.evo-table th {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  font-weight: normal !important;
  background: var(--evogunmetal);
  color: #fff;
  border: 1px solid var(--evogunmetal);
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 0.5rem;
}

.evo-table td {
  color: #6c757d !important;
  font-weight: normal !important;
  font-family: "ProximaNova-Regular", sans-serif !important;
  border: 1px solid #e3eaef;
  padding: 0.5rem;
  vertical-align: top;
}
.content-block h3 {
  color: rgba(51, 51, 51, 0.81);
  font-size: 18px;
  margin-top: 15px;
}

.content-block {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 30px;
  background-color: #fff;
}

.flight-details-container,
.aircraft-options-container {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  margin-bottom: 30px;
  padding: 30px;
}

.flight-details-container h3,
.aircraft-options-container h3 {
  margin-bottom: 20px;
}

.flight-details-block:not(:first-child) {
  padding-bottom: 20px;
}

.flight-details-block:last-of-type {
  padding-bottom: 10px;
}

#app {
  background-color: #f9fbfd;
}

/* this is the flight table classname */
.ddahQf {
  /* box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%); */
  background: #fff;
}

#search-bulk-actions-container #searchFlights,
.css-yk16xz-control {
  background-color: transparent !important;
}
#addFlight {
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  padding: 40px 35px;
  margin-top: 70px !important;
}

.server-pagination-table-container,
.standard-data-table-container {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  /* margin-top: 25px; */
}

.server-pagination-table-container {
  margin-top: 25px;
  background: #fff;
}

.server-pagination-table > div {
  width: 105%;
}

#returnToEditor {
  margin-top: 30px;
  border: none;
  background: var(--evogunmetal);
  color: #fff;
  padding: 10px;
  width: 100%;
}

#emailHeaders {
  margin-top: 30px;
  margin-bottom: 30px;
}

#emailHeaders h3 {
  margin-bottom: 20px;
}

/* menu css */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--evogunmetal);
  transition: 0.35s all ease;
  height: 15% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--evoblue);
  transition: 0.35s all ease;
}

a.bm-item,
button.bm-item,
a.menu-item {
  color: #fff;
  transition: transform 0.8s cubic-bezier(0.7, 0, 0.3, 1) 0s, color 0.35s ease !important;
  margin: 15px 0;
}

a.bm-item:hover,
button.bm-item:hover,
a.menu-item:hover {
  color: var(--evoblue);
  /* transition: 0.35s all ease; */
}

.bm-item:focus {
  outline: none;
}

.submenu a.menu-item {
  display: block;
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 7px;
  margin: 0;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--evogunmetal);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--evogunmetal);
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/* end menu css */

.menuToggle {
  background: none;
  border: none;
  padding: 0 !important;
  color: var(--evoblue);
  font-family: "ProximaNova-Semibold" !important;
}

/* div.submenu.businessMenu.closed {
  display: none !important;
}

div.submenu.businessMenu.open {
  display: block !important;
} */

.logo-container {
  transition: all 0.5s ease-in-out;
}

#app {
  overflow: scroll !important;
}

.bm-item {
  display: flex !important;
  align-items: center;
}

.bm-item .material-icons {
  padding-right: 10px;
  font-size: 20px;
}

.breadcrumb .material-icons {
  color: #ced4da;
}

.evo-table {
  width: 100%;
}

.evo-table td {
  padding: 15px;
  font-size: 15px;
}

.content-block h3 {
  font-family: "ProximaNova-Bold" !important;
  font-weight: normal !important;
  margin-bottom: 20px;
}

.menu-item.selected-link {
  color: var(--evoblue);
}

.navbar {
  transition: all 0.5s ease-in-out;
}

/* .menu-open.navbar {
  transform: translate3d(280px, 0px, 0px);
  transition: all 0.5s ease-in-out;
} */

.menu-open.navbar .logo-container {
  margin-left: 0;
}

.menu-closed.navbar {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.5s ease;
}

#newQuote input[type="text"],
#newQuote input[type="email"],
#newQuote .css-yk16xz-control,
#newQuote .rdw-editor-wrapper,
#qfsForm .select__control {
  background: #fff !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.bm-item.closed {
  display: none !important;
}

#sendTestEmail input[type="submit"],
#sendLiveEmail input[type="submit"] {
  background-color: var(--evoblue);
}
.aircraft-options-block:not(:first-of-type) {
  padding-top: 20px;
}

.flight-details-container,
.aircraft-options-container {
  background: #fff;
}

button:focus {
  /* color: red; */
}

.mt-30 {
  margin-top: 30px !important;
}

.select__input > input {
  margin: 0 !important;
}

.form-group .email-select,
.form-group .organization-select,
.form-group .base-select {
  margin-bottom: 20px;
}

.form-group > .email-select > .select__control,
.form-group > .organization-select > .select__control {
  background-color: #fff !important;
}

.permissions-list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.permissions-list h3 {
  flex-basis: 100%;
}

.permissions-list label {
  font-family: "ProximaNova-Semibold";
  font-weight: normal;
  text-transform: uppercase;
  margin-left: 10px;
}

#addAirportHeading {
  margin-top: 30px;
}

.base-heading {
  text-align: center;
  margin-top: 30px;
}

.new-org-button {
  color: #fff;
  background: var(--evoblue);
  padding: 8px 20px;
  margin: 20px 0 10px;
  display: inline-block;
  width: auto;
}

.new-org-button:hover {
  color: #fff;
  background: var(--evogunmetal);
}

.btn {
  transition: 0.35s all ease;
  outline: none;
  font-family: "ProximaNova-Semibold";
}

/* body .nav-container {
  transition: all 6s ease-in-out !important;
}

body.main-menu-open .nav-container {
  width: calc(100% - 280px);
  transition: all 6s ease-in-out !important;
} */

body #page-wrap {
  transition: all 0.5s ease-in-out !important;
}

body.main-menu-open #page-wrap {
  width: calc(100% - 280px);
  transition: all 0.5s ease-in-out !important;
}

/* .menu-open.navbar {
  width: calc(100% - 280px);
  transition: all 0.5s ease-in-out !important;
} */

.main-menu-open .logo-container {
  transform: translateX(280px);
}

#quote-actions-row,
.header-actions-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}
#quote-actions-row input[type="text"],
.header-actions-row {
  margin-right: 20px;
}

html,
body {
  overscroll-behavior-x: none;
}

.mr-30 {
  margin-right: 30px;
}
.mr-10 {
  margin-right: 10px;
}

@media only screen and (max-width: 450px) {
  .logo-container {
    margin-left: 60px;
  }

  .navbar-nav:last-child {
    display: none;
  }

  #searchFlights,
  #lead-bulk-actions .owner-select {
    width: 100%;
  }

  #filterByStage {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  #lead-bulk-actions .owner-select {
    margin-bottom: 20px;
  }

  button.lead-outreach-assignment {
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .content-block {
    overflow-x: scroll;
    width: 100%;
  }

  .content-block .evo-table {
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
  }

  #page-header h1.page-title {
    font-size: 20px;
    padding-bottom: 0;
  }

  #page-header .page-pretitle {
    margin-top: 10px;
  }
  #page-breadcrumb {
    margin-left: 0;
  }

  .bm-burger-button {
    left: 10px;
  }

  #search-bulk-actions-container.homepage {
    width: 100%;
    flex-direction: column;
  }
  #search-bulk-actions-container.homepage #searchFlights {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  #search-bulk-actions-container.homepage #table-bulk-actions {
    flex-basis: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  #search-bulk-actions-container.homepage
    #table-bulk-actions
    .basic-multi-select,
  #search-bulk-actions-container.homepage #table-bulk-actions #deleteButton {
    flex-basis: 100%;
    margin: 15px 0;
    width: 100%;
  }

  #search-bulk-actions-container.homepage #table-bulk-actions #deleteButton {
    padding: 10px 0;
    margin-bottom: 30px;
  }
}

#filterByStage {
  align-self: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.header-actions-row.has-delete-btn #table-bulk-actions {
  flex-basis: auto;
  align-self: center;
  margin-bottom: 0;
}

.departure-airport-select > div,
.arrival-airport-select > div,
.react-date-picker__wrapper {
  background-color: #fff !important;
}

.outside-org-calculator .rdt_TableCol {
  background: var(--evogunmetal);
  color: #fff !important;
}

.outside-org-calculator .rdt_TableCol .rdt_TableCol_Sortable {
  color: #fff !important;
}

#results.outside-org-calculator {
  text-align: center;
}

#results.outside-org-calculator > div:first-of-type {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%) !important;
  display: inline-block !important;
  width: auto !important;
  background: transparent;
  margin-bottom: 20px;
  margin-top: 10px;
}

.calculatorForm {
  text-align: left !important;
}

#results.outside-org-calculator
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:first-child {
  color: var(--evogunmetal);
  font-family: "ProximaNova-Semibold";
}

form.updateDocumentForm.mt-20 {
  margin-top: 20px !important;
}

#quoteEmailPreview.outsideOrgPreview {
  height: 75vh;
}

.form-group .material-icons {
  position: relative;
  right: -90%;
  top: -52.5px;
}

.password-container .material-icons {
  /* opacity: 0.5; */
  color: var(--evogunmetal);
  transition: 0.35s all ease-in-out;
  cursor: pointer;
}

.password-container .material-icons:hover {
  /* opacity: 0.5; */
  color: var(--evoblue);
}

@media only screen and (max-width: 999px) {
  .form-group .material-icons {
    right: -85%;
  }
}

@media only screen and (max-width: 768px) {
  .form-group .material-icons {
    right: -92%;
  }
  #addAirport .form-group,
  form.updateDocumentForm .form-group,
  form.base-form.two-column .form-group {
    flex-basis: 100%;
    margin-right: 0;
  }

  #addAirport .form-group:nth-of-type(even),
  form.updateDocumentForm .form-group:nth-of-type(even),
  form.base-form.two-column .form-group:nth-of-type(even) {
    margin-left: 0;
  }
}

.centered-btn {
  margin: 25px auto 0;
  display: inline-block;
  text-align: center;
}

.centered-content-container {
  text-align: center;
}

.leg-duration-block .form-group {
  margin: 20px 0 20px 0;
  flex-basis: 20%;
  /* justify-content: space-between; */
}

.leg-duration-block .form-group input {
  width: 95%;
}

/* .leg-duration-block .form-group:nth-of-type(even) {
  margin-right: 10px;
} */

.leg-duration-block {
  flex-direction: row;
  flex-wrap: wrap;
}

.warning-text {
  color: #de0000;
}

#leg-block-container,
.leg-container {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.legHeading {
  font-size: 26px !important;
  text-transform: uppercase;
  color: var(--evogunmetal);
  font-family: "ProximaNova-Bold";
  font-weight: normal !important;
  text-align: center;
}

.widget-title {
  text-align: center;
}

.link-button {
  display: block;
  text-align: center;
}

.widget-footer-button {
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

#searchFlights.opi-search {
  flex-basis: 40%;
}

.header-actions-row.opi-header {
  margin-right: 0;
}

.centered {
  text-align: center;
}

.opi-score {
  font-size: 20px;
}

.operator-type-select {
  margin-bottom: 20px;
}

.operator-type-select > .select__control {
  background: #fff !important;
}

#sales-widgets-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.phoneCallWidgetTableContainer {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  margin-top: 25px;
}

#phoneCall-widget {
  margin-top: 30px;
}

@media only screen and (min-width: 1000px) {
  body.main-menu-open .ReactModal__Content {
    left: 140px !important;
  }

  .opi-auto-width {
    max-width: 1200px;
    margin: 25px auto 0 auto;
  }
}

.full-width-row {
  width: 100%;
  flex-basis: 100%;
}

.countryCode-select .select__control {
  background: #fff !important;
  margin-bottom: 20px;
}

.ml-2p {
  margin-left: 2%;
}

.ml-10 {
  margin-left: 10px;
}

.b-2 {
  bottom: 2;
}
.p-relative {
  position: relative;
}

.select-mr-20 {
  margin-right: 20px;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-4p {
  margin-left: 4% !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

#recordWidgetControls {
  width: 50%;
  margin: 0 auto;
}

#recordWidgetContainer h1 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.w-100 {
  width: 100%;
}

.d-block {
  display: block;
}
.date-range-picker-container {
  /* margin: 0 auto; */
  display: flex;
  margin-bottom: 20px;
}

.date-range-picker-container .rdrDateRangePickerWrapper {
  margin: 0 auto;
}

#recordWidgetResults {
  width: 75%;
  margin: 20px auto 0;
}
#recordWidgetResults h4 {
  text-align: center;
}
#breakdownByAssignee {
  margin: 30px 0;
}

.dataTableWithShadow {
  /* display: inline-block; */
  display: flex;
}

.innerDataTableContainer {
  margin: 0 auto;
  text-align: center;
  width: auto;
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
}

.rdrDateRangePickerWrapper {
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
}

.recordType-select .select__control {
  background: #fff !important;
}

.owner-phone-select {
  width: 250px;
}

.matchData:not(:nth-child(1)) {
  margin-left: 4px;
}

@media only screen and (max-width: 768px) {
  #recordWidgetControls,
  .rdrDefinedRangesWrapper,
  .rdrCalendarWrapper,
  .rdrMonth,
  #recordWidgetResults,
  #breakdownByAssignee,
  #breakdownByDate {
    width: 100% !important;
    overflow: scroll;
  }

  #recordWidgetContainer h1 {
    font-size: 26px;
    margin-top: 30px;
  }

  .text-center.hero {
    margin-top: 15px;
  }

  .hero p.lead {
    margin-bottom: 0;
  }

  .date-range-picker-container .rdrDateRangePickerWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.dkXIso:nth-of-type(n) {
  background-color: rgb(12 179 215 / 10%) !important;
}

.fm-l2-parent-table-head th,
.fm-l2-child-table-heading,
.fm-l2-child-table-head > tr > th {
  font-family: "ProximaNova-Semibold" !important;
}

.fm-l2-parent-table-body th,
.fm-l2-parent-table-body td,
.fm-l2-child-table-body td,
.fm-l2-child-table-body th {
  font-family: "ProximaNova-Regular" !important;
}

.fm-l2-parent-table-row td th,
.fm-l2-parent-table-row {
  font-family: "ProximaNova-Regular" !important;
}

.fm-l2-parent-table-head {
  background-color: var(--evogunmetal);
}

.fm-l2-parent-table-head-row th {
  color: #fff !important;
}

.fm-l2-child-table-container {
  padding-bottom: 20px;
}

.fm-l2-parent-table-body th,
.fm-l2-parent-table-body td,
.fm-l2-child-table-body td {
  border-left: 1px solid #e3eaef;
  border-right: 1px solid #e3eaef;
  border-bottom: 1px solid #e3eaef;
}

.fm-l2-child-table-body td {
  border-bottom: 1px solid #e3eaef !important;
}

/* flight legs l2 tabbed table heading rows */
.fm-l2-child-table-head > tr > th,
.fm-l2-parent-table-head th {
  border-bottom: none !important;
}

/* .css-1howxi1-MuiTableCell-root {
  border-left: 1px solid var(--evogunmetal);
  border-right: 1px solid var(--evogunmetal);
} */

.mt-20 {
  margin-top: 20px;
}

.w-200 {
  width: 200px;
}

.fm-l2-child-table-head {
  background: transparent !important;
  border: 1px solid #e3eaef !important;
}

.fm-l2-child-table-head > tr > th {
  color: var(--evogunmetal) !important;
  border: 1px solid #e3eaef !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fm-l2-parent-table-row td {
  font-size: 17px;
  font-family: "ProximaNova-Semibold" !important;
}

.leadFlightLegMatches:nth-child(2) {
  margin-left: 5px;
}

.flightMatchWidgetHeading {
  margin-top: 30px;
}

#createProspectModalForm {
  width: 100%;
}

#createProspectModalForm h3 {
  text-align: center;
}

#createProspectModalForm .PhoneInputInput,
#createProspectForm .PhoneInput input {
  margin-bottom: 0;
}

.ReactModal__Content--after-open {
  overflow: visible !important;
}

.prospect-form-select {
  margin-bottom: 20px;
}

.action-button {
  padding: 10px 20px;
}

.fb-100 {
  flex-basis: 100% !important;
}

.fb-50 {
  flex-basis: 50%;
}

.fb-48 {
  flex-basis: 48%;
}

.mr-2pct {
  margin-right: 2%;
}

.ml-2pct {
  margin-left: 2%;
}

#lead-bulk-actions #filterByStage .stage-select {
  width: auto;
  margin-right: 0;
}

body:not(.main-menu-open) .nav-container {
  position: fixed;
  width: 100%;
  z-index: 99999;
}

body:not(.main-menu-open) main#page-wrap {
  margin-top: 100px;
}

.Toastify__toast-container {
  z-index: 999999999;
}

.mt-100 {
  margin-top: 100px;
}

.w-100pct {
  width: 100% !important;
}

.Toastify__toast--success,
.Toastify__toast--error {
  color: #fff !important;
}

.Toastify__progress-bar--success,
.Toastify__progress-bar--error {
  background-color: hsla(0, 0%, 100%, 0.7);
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 0.7;
}

.Toastify__toast--error {
  background-color: #e74c3c;
}

/* css grid tables */

/* flexbox tables */

/* Variables
================================== */
/* Tables
================================== */
.flex-table {
  display: flex;
  margin: 0 0 3em 0;
  padding: 0;
  /* grid-template-columns: repeat(6, auto); */
  /* white-space: nowrap; */
}

.flex-table > div {
  align-self: stretch;
  border-bottom: 1px solid #e3eaef;
  /* bor: 1px solid #e3eaef; */
}

.flex-table-cell {
  /* box-sizing: border-box; */
  /* flex-grow: 1; */
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
}
.flex-table-cell > h1,
.flex-table-cell > h2,
.flex-table-cell > h3,
.flex-table-cell > h4,
.flex-table-cell > h5,
.flex-table-cell > h6 {
  margin: 0;
}
/* Table column sizing
================================== */
.flex-table--2cols > .flex-table-cell {
  width: 50%;
}
.flex-table--3cols > .flex-table-cell {
  width: 33.33%;
}
.flex-table--4cols > .flex-table-cell {
  width: 25%;
}
.flex-table--5cols > .flex-table-cell {
  width: 20%;
}
/* Apply styles
================================== */
.flex-table {
  position: relative;
  top: 3px;
  left: 3px;
}
.flex-table-cell {
  margin: -3px 0 0 -3px;
  /* background-color: white;
  border-color: #e2e6e9; */
}
/* Cell styles
================================== */

.flex-table .flex-table-cell--head {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  font-weight: normal !important;
  background: var(--evogunmetal);
  color: #fff;
  border: 1px solid var(--evogunmetal);
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 0.5rem;
  z-index: 99;
}

.flex-table .flex-table-cell--body {
  color: #6c757d !important;
  font-weight: normal !important;
  font-family: "ProximaNova-Regular", sans-serif !important;
  /* border: 1px solid #e3eaef; */
  padding: 0.5rem;
  vertical-align: top;
  border-top: 0;
  border-right: 0;
  z-index: 98;
  padding: 15px;
  height: 100%;
  /* border: 1px solid #e3eaef; */
}

/* Responsive
==================================== */
@media all and (max-width: 500px) {
  .flex-table--collapse {
    display: block;
  }
  .flex-table--collapse > .flex-table-cell {
    /* width: 100% !important; */
  }
  .flex-table--collapse > .flex-table-cell--foot {
    margin-bottom: 1em;
  }
}
.no-flexbox .flex-table {
  display: block;
}
.no-flexbox .flex-table > .flex-table-cell {
  width: 100%;
}
.no-flexbox .flex-table > .flex-table-cell--foot {
  margin-bottom: 1em;
}

.o-0 {
  order: 0;
}

.o-1 {
  order: 1;
}

.o-2 {
  order: 2;
}
.o-3 {
  order: 3;
}
.o-4 {
  order: 4;
}

.o-5 {
  order: 5;
}

.o-7 {
  order: 7;
}
.o-8 {
  order: 8;
}
.o-9 {
  order: 9;
}
.o-10 {
  order: 10;
}
.o-11 {
  order: 11;
}
.o-12 {
  order: 12;
}
.o-13 {
  order: 13;
}
.o-14 {
  order: 14;
}
.o-15 {
  order: 15;
}
.o-16 {
  order: 16;
}
.o-17 {
  order: 17;
}

.disabledTillClicked {
  cursor: pointer;
  background: var(--evoBlueTenPercent);
  /* color: #fff !important; */
  border-color: var(--evoBlueTenPercent) !important;
}

.phoneDisabledTillClicked > input {
  cursor: pointer;
  background: var(--evoBlueTenPercent);
  /* color: #fff !important; */
  border-color: var(--evoBlueTenPercent) !important;
}

.standardTextInput {
  transition: all 0.25s ease;
}

.prospect-form-select.selectDisabledTillClicked .select__control {
  background-color: var(--evoBlueTenPercent) !important;
  color: #495057 !important;
  border-color: var(--evoBlueTenPercent) !important;
  cursor: pointer;
}

.prospect-form-select .select__placeholder {
  color: #495057 !important;
}
.prospect-form-select.selectDisabledTillClicked .select__dropdown-indicator {
  color: #495057 !important;
}
.prospect-form-select.selectDisabledTillClicked .select__indicator-separator {
  background-color: #495057 !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  overflow-x: hidden !important;
}
.table-cell-centered {
  text-align: center !important;
}

#results.outside-org-calculator > div:first-of-type {
  width: 100% !important;
  box-shadow: none !important;
}

#results.outside-org-calculator div.rdt_Table {
  /* width: 100% !important; */
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%) !important;
}

.base-select {
  width: 100%;
  /* margin-bottom: 20px; */
}

.base-select .select__control {
  background: #fff !important;
}
.mt-30 {
  margin-top: 30px;
}

.base-select input {
  margin-bottom: inherit !important;
}

.base-select {
  font-family: "ProximaNova-Semibold" !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-20 {
  margin-top: 20px !important;
}

.tableExpandIconMobile {
  padding: 5px !important;
  text-align: center !important;
}

.accordion-table-with-details p {
  font-size: 16px;
}

.mobile-lead-details-initial-cell {
  line-height: 32px !important;
}

.lead-info-icon {
  position: relative;
  top: 5px;
  /* right: 3px; */
  margin-right: 12px;
  color: var(--evoblue);
  font-size: 20px;
  /* display: none !important; */
}

@media only screen and (max-width: 768px) {
  #page-title-container,
  #page-breadcrumb {
    align-self: flex-start;
  }

  #page-header {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .breadcrumb {
    margin-bottom: 0;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 450px) {
  #page-header .page-pretitle {
    margin-top: 10px;
    margin-bottom: 7px;
    font-size: 12px;
  }

  #page-header h1.page-title {
    font-size: 19px;
    padding-bottom: 0;
    margin-bottom: 1px;
  }
}

.button-tag-list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: scroll;
  /* flex-shrink: 0; */
  /* flex-grow: 1;/ */
  flex: 0 0 auto;
  height: auto;
  margin-top: 10px;
}

.button-tag-list button {
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px 0px rgb(154 161 171 / 10%);

  flex-shrink: 0;
}

.button-tag-list button:not(.tag-active) {
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
}

.button-tag-list button.tag-active {
  background-color: var(--evoblue);
}
.titleCase:first-letter {
  text-transform: capitalize !important;
}

.titleCase:first-letter {
  text-transform: uppercase !important;
}

#developerPage p {
  text-align: center;
}

#developerPage {
  width: 80%;
  margin: 0 auto;
}

#developerPage .input-icon {
  color: var(--evoblue);
  left: 97%;
}

.no-border {
  border: none !important;
}

.centered-important {
  text-align: center !important;
}

.MuiIconButton-root {
  outline: none !important;
}

.back-to-top-icon {
  display: block;
  position: absolute;
  bottom: 12px;
  right: 8px;
  background: rgba(32, 53, 68, 0.9);
  padding: 9px;
  color: #fff;
  border-radius: 50%;
  font-size: 21px;
  text-align: center;
  opacity: 0;
  transition: all 0.25s ease;
}

#lead-bulk-actions.mobile-version {
  width: 100%;
  align-items: center;
}

#lead-bulk-actions.mobile-version > .owner-select,
#lead-bulk-actions.mobile-version > .stage-select,
#mobile-lead-sort-container > .sortBy-select,
#mobile-lead-sort-container > .sortDirection-select {
  width: 100%;
  flex-basis: 48%;
  margin-bottom: 0;
  /* margin-right: 0; */
}

#lead-bulk-actions.mobile-version > .owner-select,
#mobile-lead-sort-container > .sortBy-select {
  margin-right: 2%;
  margin-left: 0;
}

#lead-bulk-actions.mobile-version > .stage-select,
#mobile-lead-sort-container > .sortDirection-select {
  margin-left: 2%;
  margin-right: 0;
}

#mobile-lead-sort-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.mobile-leads-header-heading {
  font-size: 18px;
  color: var(--evogunmetal);
  margin: 0;
  text-align: center;
  font-family: "ProximaNova-Bold" !important;
  font-weight: normal !important;
}

.lead-search {
  background: transparent;
}

.base-button.evo-blue[disabled] {
  background: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

.lead-pagination-button {
  display: flex;
  align-items: center;
  padding: 6px;
}

#evo-lead-pagination {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  text-align: center;
  /* align-items: center; */
  margin: 20px auto 0 auto;
}

#evo-lead-pagination > * {
  /* flex-basis: 33.333%; */
  text-align: center;
  /* align-self: center; */
  margin: 0 auto;
}

#evo-lead-pagination > select {
  width: 24%;
}

#evo-lead-pagination > button {
  padding: 8px 8%;
}

#evo-lead-pagination > .prev-lead-pagination {
  margin-left: 0;
}
#evo-lead-pagination > .next-lead-pagination {
  margin-right: 0;
}

.evo-pagination-page-count {
  text-align: center;
  font-size: 18px;
  font-family: "ProximaNova-Semibold";
  margin-top: 20px;
}

@media only screen and (max-width: 450px) {
  .navbar {
    padding: 13px 0;
  }

  .bm-burger-button {
    top: 22px;
  }

  .logo-container > a > img {
    width: 210px;
  }

  .logo-container {
    margin-left: calc(50vw - 105px);
  }

  .fm-l2-parent-table-row td {
    font-size: 16.5px;
  }

  td.mobile-lead-details-initial-cell {
    padding: 12px;
  }
}

.lead-assignee-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
}

.lead-unassigned-indicator {
  color: rgba(0, 0, 0, 0.4);
}

.lead-assigned-indicator {
  color: var(--evogunmetal);
}

.mobile-lead-details-initial-cell {
  position: relative;
}

#filterByOwner {
  /* margin-left: 10px; */
}

#filterByOwner > div {
  margin-top: 0;
}

.min-w-200 {
  min-width: 200px;
}

@media only screen and (max-width: 768px) {
  #lead-widget,
  .server-pagination-table-container {
    width: 100%;
  }

  h3.widget-title:nth-child(1) {
    margin-top: 20px;
  }
}

.sortBy-select > div > div > div,
.sortDirection-select > div > div > div,
.stage-select > div > div > div > div,
.owner-select > div > div > div,
.stage-select > div > div > div,
.select__placeholder,
input#searchFlights::placeholder {
  font-family: "ProximaNova-Semibold" !important;
}
#countPerPage {
  /* -webkit-appearance: none; */
  border-radius: 0 !important;
  color: var(--evogunmetal);
  background: #fff;
  border-color: var(--evogunmetal);
}

.pl-0 {
  padding-left: 0 !important;
}

.linked-heading {
  color: var(--evogunmetal);
  font-family: "ProximaNova-Semibold";
  font-weight: normal !important;
}

.lead-has-matches-indicator.superAdminOrAd {
  top: 10px;
}

.lead-has-matches-indicator.superAdminOrAd {
  top: 45px;
}

body h1,
body .h1 {
  font-family: "ProximaNova-Bold", sans-serif !important;
  font-weight: normal !important;
}

.fm-l2-child-table-body td strong,
.fm-l2-child-table-body th strong,
.fm-l2-parent-table-body td strong,
.fm-l2-parent-table-body th strong,
.fm-l2-parent-table-row strong,
.fm-l2-parent-table-row td th strong,
body strong,
span strong,
p strong,
ul strong,
div strong,
.h2,
.h3,
.h4,
.h5,
.h6,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  font-weight: normal !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.material-icons.sub-menu-icon {
  position: relative;
  top: 3px;
}

.lead-internal-notes {
  /* padding-top: 100px !important; */
  /* padding-bottom: 100px !important; */
  /* padding-left: 16px;
  padding-right: 16px; */
}

.lead-l2-notes-textarea-container {
  /* margin-top: 30px; */
  /* padding: 25px 16px; */
  padding-top: 10px;
  margin-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.lead-notes-heading {
  padding: 15px;
  background: var(--evogunmetal);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.lead-l2-notes-container {
  margin-top: 20px;
}

#leads-l2-mobile-header {
  text-align: center !important;
  font-size: 16px !important;
}

@media only screen and (max-width: 768px) {
  .lead-notes-heading {
    font-size: 16px !important;
  }

  .base-header-actions-row {
    margin: 10px 0 15px;
    width: 100% !important;
  }

  .base-header-actions-row > * {
    flex-basis: 48% !important;
    margin-right: 0;
  }

  .base-header-actions-row > .base-button {
    text-align: center;
    margin-left: 4%;
  }
  body:not(.main-menu-open) main#page-wrap {
    margin-top: 82px;
  }
}

@media only screen and (min-width: 769px) {
  .base-header-actions-row > .base-button {
    text-align: center;
    margin-left: 20px;
  }
}

.opi-table {
  margin-top: 15px;
}

#page-header h1.page-title,
#page-header .page-pretitle {
  margin-bottom: 0;
}
#page-header .page-pretitle {
  margin-bottom: 5px;
}

#newTripHeading {
  margin-top: 40px;
}

#newTrip {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
  left: 0 !important;
}

.mbsc-ios.mbsc-textfield {
  padding: 0 !important;
}

#lead-bulk-actions > div {
  max-width: 20%;
}

#lead-bulk-actions {
  gap: 1%;
}

.main-menu-open #lead-bulk-actions > div {
  max-width: unset;
  margin: 0 0 20px 0 !important;
}

@media only screen and (max-width: 1499px) {
  .main-menu-open #lead-bulk-actions > div {
    flex-basis: 32%;
  }
}

@media only screen and (max-width: 1250px) {
  .main-menu-open #lead-bulk-actions > div {
    flex-basis: 40%;
    flex-grow: 1;
  }

  #lead-bulk-actions > div {
    flex-basis: 40%;
    flex-grow: 1 !important;
    max-width: unset;
    margin: 0 0 20px 0 !important;
  }
}
.base-radio-group .MuiFormGroup-root label {
  text-transform: none !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "ProximaNova-Regular" !important;
}

.amenities-toggle-list,
.base-toggle-list {
  display: flex !important;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}

.amenities-toggle-list button,
.base-toggle-list button {
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
  border-radius: 4px !important;
  /* margin:10px 0 10px 0!important; */
  color: rgba(0, 0, 0, 0.54) !important;
  font-family: "ProximaNova-Semibold" !important;
}

.amenities-toggle-list .Mui-selected,
.base-toggle-list .Mui-selected {
  background-color: var(--evogunmetal) !important;
  color: #fff !important;
  border-color: var(--evogunmetal) !important;
}

.breakdown-table th {
  font-size: 16px !important;
}

.breakdown-table td {
  font-size: 16px;
  font-family: "ProximaNova-Regular";
}

.breakdown-table h5 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
}

.breakdown-table .black-bottom-border {
  border-bottom: 2px solid var(--evogunmetal) !important;
}

th.total-cell-larger-text,
td.total-cell-larger-text {
  font-size: 17px !important;
}

.breakdown-table thead th {
  background: var(--evogunmetal);
  color: #fff !important;
  border-bottom-color: var(--evogunmetal);
}

.gunmetal-text {
  color: var(--evogunmetal) !important;
}

#sourcing-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

#sourcing-container > * {
  flex-basis: 50%;
}

#breakdown {
  padding: 0 2%;
  position: sticky;
  top: 110px;
}

#sort-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  #sort-container {
    flex-direction: column;
    gap: 0;
  }

  #sort-container > * {
    width: 100%;
  }
  #sourcing-container {
    flex-direction: column;
  }

  #sourcing-container > * {
    flex-basis: 100%;
    width: 100%;
  }

  #sourcingModuleForm {
    padding: 0 2%;
  }
}

.fg-1 {
  flex-grow: 1;
}

#confirmationButtons {
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
  flex-direction: row;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

#confirmationButtons button {
  font-size: 18px;
  padding: 10px 20px;
}
.delete-button-shadow {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset,
    rgb(212 63 58 / 60%) 0px 0px 8px;
}

#submitAndViewLead {
  background-color: var(--evogunmetal);
}

#submitAndViewLead:hover {
  background-color: var(--evoblue);
}

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-20 {
  gap: 20px;
}

.p-12 {
  padding: 12px;
}

.text-evo-blue {
  color: var(--evoblue);
}

.sourcing-record-hidden {
  background: rgba(0, 0, 0, 0.04);
}

.sourcing-record-hidden td {
  border-color: #cbcbcb;
}

.mr-20 {
  margin-right: 20px;
}

.hidden {
  display: none !important;
}

.sourcing-record-hidden td,
.sourcing-record-hidden td a {
  color: #00000054;
}

.operator-select .select__single-value,
.operator-select .select__single-value > span {
  width: 100%;
}
.operator-select .select__single-value > span {
  position: relative;
  width: 100%;
  display: block;
}

.select-label-indicator {
  top: 2.5px !important;
  position: absolute !important;
  right: 0px !important;
  left: 97% !important;
  font-size: 18px;
}

.operator-select .select__menu-list .select-label-indicator {
  top: unset !important;
  position: unset !important;
  right: unset !important;
  left: unset !important;
  width: unset !important;
  margin-left: auto;
}

.operator-select-option-holder {
  /* width:100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.select__menu-list .operator-select-option-holder[aria-label]:after {
  opacity: 0;
  content: attr(aria-label);
  padding: 2px 8px;
  position: absolute;
  left: 0%;
  top: -80%;
  white-space: nowrap;
  z-index: 20;
  background: rgba(32, 53, 68, 0.9);
  transition: opacity 0.5s;
  pointer-events: none;
  color: #fff;
}

.operator-select-option-holder.hasToolTip {
  cursor: pointer;
}

.operator-select-option-holder {
  padding: 1px 0;
}

.select__menu-list
  .operator-select-option-holder.hasToolTip[aria-label]:hover:after {
  opacity: 1;
  /* transition-delay:0.15s; */
}

.select__value-container .operator-select-option-holder[aria-label]:after {
  opacity: 0;
  content: attr(aria-label);
  padding: 2px 8px;
  position: absolute;
  top: 0;
  right: 0 !important;
  left: 50% !important;

  white-space: nowrap;
  z-index: 20;
  background: rgba(32, 53, 68, 0.9);
  transition: opacity 0.5s;
  pointer-events: none;
  color: #fff;
}

.select__value-container
  .operator-select-option-holder.hasToolTip[aria-label]:hover:after {
  opacity: 1;

  /* transition-delay:0.15s; */
}

.disabled-link {
  pointer-events: none;
}

.three-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8%;
}

div.server-pagination-table-container
  > div.server-pagination-table
  > div
  > div
  > div:nth-child(2) {
  overflow-y: scroll;
  overflow-x: hidden;
}

/*  .server-pagination-table {  overflow-x: auto;
    overflow-y: hidden; }*/

.phone-call-stage-select {
  min-width: 200px;
}

.record-quote-sent .sourcing-record-checkbox {
  color: var(--evogreen) !important;
}

.record-quote-draft .sourcing-record-checkbox {
  color: var(--evoburgundy) !important;
}

.base-radio-group label span {
  font-family: "ProximaNova-Semibold" !important;
}

.sourcing-table .fm-l2-parent-table-row td,
.unavailable-operator-table .fm-l2-parent-table-row td {
  font-size: 15px;
  padding: 3px 10px;
}

.sourcing-table .fm-l2-parent-table-head-row th,
.unavailable-operator-table .fm-l2-parent-table-head-row th {
  padding: 10px;
  position: relative;
}

.sourcing-table .fm-l2-parent-table-head-row th .material-icons.sort-icon,
.unavailable-operator-table
  .fm-l2-parent-table-head-row
  th
  .material-icons.sort-icon {
  position: absolute;
  top: 0.25rem;
  font-size: 30px;
  color: var(--evoblue);
  line-height: 1;
  /* right: 0; */
}

.mobile-unavailable-table-cell p {
  font-family: "ProximaNova-Regular";
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

.mobile-unavailable-table-cell {
  position: relative;
}

.mobile-unavailable-table-cell .delete-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  margin-right: 0;
  padding: 10px 10px 3px;
}

.mobile-unavailable-table-cell .delete-icon > span {
  font-size: 16px;
  line-height: 0;
}

.sortable-evo-header {
  cursor: pointer;
}

.mr-2pct-important {
  margin-right: 2% !important;
}

.ml-2pct-important {
  margin-left: 2% !important;
}

.additional-user-modal .PhoneInputInput {
  margin-bottom: 0 !important;
}

.d-flex-wrap-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

/* messages */

.message-list {
  list-style: none;
  flex-wrap: nowrap;
  flex-direction: column;
  display: flex;
  padding: 0 20px;
  max-height: 500px;
  overflow: scroll;
}

.message-list li {
  display: flex;
  margin: 5px 0;
}

.message-list li:last-of-type {
  margin-bottom: 0;
}

.message-list li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.message-list li .message {
  background-color: #e5e6e9;
  color: #363636;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
}

.message-list li.me {
  align-self: flex-end;
}

.message-list li .message p {
  margin: 0;
}

.message-list li .message p strong {
  font-weight: bold;
  color: #4b4f56;
}

.message-list li.me .message {
  background-color: #07b3d7;
  color: #fff;
  justify-content: flex-end;
}

.message-list li.me .message p strong {
  color: #fff;
}

.message-list li .timestamp {
  font-size: 12px;
  color: #213444;
  opacity: 0.5;
  margin-top: 5px;
}

.message-list li.me .timestamp {
  color: #fff;
  opacity: 0.5;
}

/* .message-form {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.message-form input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  outline: none;
  margin: 0 10px 10px;
}

.message-form button[type="submit"] {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #4fc3f7;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
}

.message-form button[type="submit"]:hover {
  background-color: #039be5;
}

.message-form button[type="submit"]:active {
  background-color: #0277bd;
} */

.message-form {
  display: flex;
  align-items: center;
  margin: 10px 10px !important;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 2px 10px;
}

.message-form input[type="text"] {
  flex-grow: 1; /* Allows the input to grow and fill the space */
  /* padding: 10px; */
  border: none !important;
  font-size: 14px;
  outline: none;
  margin-right: 10px; /* Only right margin is needed */
}

.message-form button {
  padding: 10px 5px;
  border: none;
  color: #4fc3f7;
  /* color: #fff; */
  /* border-radius: 20px;  */
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px; /* Space between buttons */
  line-height: 0 !important;
  background: none !important;
}
.message-form button span {
  font-size: 30px !important;
}

.message-form button:hover {
  color: #039be5;
}

.pb-1 {
  padding-bottom: 1px;
}
.message-form button:active {
  /* background-color: #0277bd; */
}

.urgent-btn {
  color: var(--evoburgundy) !important; /* Red color for urgency */
}

.urgent-btn:hover {
  color: #a70000 !important;
}

.urgent-btn:active {
  color: #a70000 !important;
}

.base-form .base-select,
.base-form.modal-form {
  width: 100%;
}

.base-form.modal-form button {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .rdrDateRangePickerWrapper {
    width: 100% !important;
  }
}

.base-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: grey;
}

.base-button.evo-bluedisabled:hover {
  background-color: grey;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.quote-preview-container {
  max-width: 80%;
  border: 2px solid #333;
  margin: 20px auto;
  padding: 30px;
  max-height: 80vh;
  overflow: scroll;
}

.operator-cancellation-policies-group .two-column-group,
.operator-cancellation-policies-group .three-column-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 3%;
  align-items: center;
}

.operator-cancellation-policies-group .two-column-group > * {
  flex-basis: 48.5%;
}
.operator-cancellation-policies-group .three-column-group > * {
  flex-basis: 30%;
  margin-bottom: 0 !important;
}

.operator-cancellation-policies-group .three-column-group * {
  margin-bottom: 0 !important;
}

.contract-cancellation-policies-group .two-column-group,
.contract-cancellation-policies-group .three-column-group,
.contract-cancellation-policies-group .four-column-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 3%;
  align-items: center;
}

.contract-cancellation-policies-group .two-column-group > * {
  flex-basis: 48.5%;
}
.contract-cancellation-policies-group .three-column-group > * {
  flex-basis: 30%;
  margin-bottom: 0 !important;
}

.contract-cancellation-policies-group .three-column-group * {
  margin-bottom: 0 !important;
}

.contract-cancellation-policies-group .four-column-group > * {
  flex-basis: 22.5%;
  margin-bottom: 0 !important;
}

.contract-cancellation-policies-group .four-column-group * {
  /* margin-bottom: 0 !important; */
}

.trip-notes-module {
  margin-top: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
}

.trip-notes-table {
  margin-bottom: 20px;
}

.trip-notes-table table {
  width: 100%;
  border-collapse: collapse;
}

.trip-notes-table th,
.trip-notes-table td {
  padding: 10px;
  text-align: left;
  vertical-align: center;
  border-bottom: 1px solid #ccc;
}

.trip-notes-table th {
  background-color: var(--evogunmetal);
  color: #fff;
  text-transform: uppercase;
}

.trip-notes-table td:last-child {
  text-align: right;
}

.trip-notes-form {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.trip-notes-form label {
  margin-bottom: 10px;
}

.trip-notes-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  min-height: 100px;
}

.text-align-right {
  text-align: right !important;
}

.d-inline-block {
  display: inline-block !important;
}

.modal-body.edit-note-modal,
.modal-body.base-modal {
  padding: 0 !important;
}

.modal-body.edit-note-modal,
.modal-body.base-modal {
  padding: 0 20px !important;
  width: 100% !important;
}

.flex-align-center {
  align-items: center;
}

.delete-button {
  background-color: #dc3545;
  border: none !important;
  color: #fff !important;
  font-family: "ProximaNova-Semibold" !important;
}

.base-phone-input input[type="tel"] {
  margin-bottom: 0 !important;
}

.itinerary-preview-container {
  max-width: 100%;
  border: 2px solid #333;
  margin: 20px auto;
  padding: 15px;
  max-height: 80vh;
  overflow: scroll;
}

.trip-notes-table {
  margin-bottom: 20px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.two-column-grid > div {
  flex-basis: 49%;
}

.two-column-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  gap: 1%;
  justify-content: space-between;
}

.trip-notes-form {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.evo-dark-green {
  background-color: var(--darkspringgreen);
}

.contract-preview-container {
  width: 80%;
  height: 80vh;
  margin: 30px auto 0;
  display: block;
  border: 2px solid #333;
  padding: 10px;
}

.tight-paragraphs p {
  margin-bottom: 5px;
}

.overflow-scroll {
  /* overflow: scroll; */
  /* max-height: auto; */
}

.base-select.time-select,
.base-select.z-20 {
  z-index: 20;
}

.z-25 {
  z-index: 25;
}

.z-26 {
  z-index: 26;
}

.trip-header .owner-name,
.trip-header .created-on {
  /* letter-spacing: 0.08em; */
  color: #6c757d;
  font-family: "ProximaNova-Semibold";
  font-size: 15px;
  margin-bottom: 0;
}

.trip-header .created-on {
  text-transform: uppercase;
}

.trip-header .owner-name {
  font-size: 20px;
}

.trip-header {
  margin-top: 30px;
}

.trip-header h1 {
  margin-top: 20px;
  line-height: 28px !important;
  font-size: 24px !important;
  margin-top: 10px;
}

.trip-header h1 span {
  font-size: 18px !important;
}

.dynamic-accordion-table-header th {
  padding: 7px;
  font-size: 15px;
  text-transform: uppercase;
}

.required {
  color: var(--evoburgundy);
  /* font-size: 20px; */
}

.ReactModal__Content--after-open {
  overflow-y: scroll !important;
  /* max-height: 100%; */
}

.flex-row.four-columns > * {
  flex-basis: 24.5%;
  margin-bottom: 0 !important;
}

.flight-details-block:not(:last-of-type) {
  border-bottom: 1px solid var(--evogunmetal);
  margin-bottom: 30px;
}

.mbsc-popup-wrapper {
  z-index: 9999999999;
}

.pb-20 {
  padding-bottom: 20px;
}
#modal-container {
  max-height: 90vh;
}

#createFBOModalForm {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

#createFBOModalForm h3 {
  text-align: center;
}

.width-100 {
  width: 100% !important;
}

.cell-padding-10-20 td {
  padding: 10px 20px;
}

.cell-padding-5-10 td {
  padding: 5px 10px;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: 10px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Transitions */
.view-transition {
  transition: opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease;
  opacity: 0;
  transform: translateX(20px);
  max-height: 0; /* Start with 0 to ensure it takes no space */
  overflow: hidden; /* Prevent content from overflowing during transition */
}

.view-visible {
  opacity: 1;
  transform: translateX(0);
  max-height: 100vh; /* Adjust based on the maximum content height */
}

/* Trips */

.calendar-wrapper {
  margin-top: 24px;
}

.trips-search {
  margin-left: auto !important;
  display: flex;
  margin-right: 20px;
  flex-basis: unset !important;
  background: transparent;
}

/* Tooltip */
.md-tooltip {
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}

.md-tooltip-header {
  padding: 12px 16px;
  color: #eee;
  background-color: var(--evogunmetal);
  display: flex;

  button {
    margin: auto;
  }
}

.md-tooltip-view-button {
  float: right;
  margin-top: -2px !important;
  padding: 0px 10px !important;
  line-height: 24px !important;
  color: #f9fbfd !important;
  border-color: #f9fbfd !important;
}

.md-tooltip-info {
  padding: 8px 16px 16px 16px;
  position: relative;
  line-height: 24px;
  background-color: #f9fbfd;
  font-size: 14px;
}

.md-tooltip-title {
  color: var(--evogunmetal);
}

.md-tooltip-text {
  font-weight: 300;
  color: var(--evogunmetal);
}

.mbsc-calendar {
  height: 1000px !important;
}

.view-transition.view-visible {
  max-height: fit-content;
}